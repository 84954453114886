import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useRef,
	useState,
} from 'react';
import { useTracking } from './tracking.provider';
import { useEnv } from '@hooks/useEnv';
import { useConfig } from './config.provider';

interface StageContextProps {
	isLivePage: boolean;
	isLiveAlreadyClosed: boolean;
	onRedirect: (v: any) => void;
	onScrollToLive: () => void;
	setIsLivePage: (v: boolean) => void;
	setLiveAlreadyClosed: (v: boolean) => void;
}

const StageContext = createContext({} as StageContextProps);

export function StageProvider({ children }: PropsWithChildren) {
	const [isLiveAlreadyClosed, setLiveAlreadyClosed] =
		useState<boolean>(false);
	const [isLivePage, setIsLivePage] = useState<boolean>(false);
	const tracking = useTracking();
	const { config } = useConfig();
	const { envIframe, envTag } = useEnv();

	const handleScrollToLive = () => {
		tracking.track({
			event: 'pip_scrollTo_button',
			event_label: 'Scroll To Live',
		});
		let player;
		if (document.querySelector(envIframe)) {
			player = document.querySelector(envIframe);
		} else if (document.querySelector(envTag)) {
			player = document.querySelector(envTag);
		}

		player?.scrollIntoView();
	};

	function onRedirect(e: any) {
		e.stopPropagation();
		if (config.live_url) {
			tracking.track({
				event: 'pip_redirect_button',
				event_label: 'Redirect To Live',
			});
			// localStorage.removeItem('bGl2ZUlk');
			window.location.replace(config.live_url);
		}
	}

	return (
		<StageContext.Provider
			value={{
				isLivePage,
				isLiveAlreadyClosed,
				onRedirect,
				onScrollToLive: handleScrollToLive,
				setIsLivePage,
				setLiveAlreadyClosed,
			}}
		>
			{children}
		</StageContext.Provider>
	);
}

export function useStage() {
	return useContext(StageContext);
}
