import React from 'react';
import LiveControl from '@components/LiveOverlay/LiveControl';
import { useVideo } from '@providers/video.provider';
import { useConfig } from '@providers/config.provider';
import { useTracking } from '@providers/tracking.provider';
import { useStage } from '@providers/stage.provider';

export function LiveOverlay() {
	const video = useVideo();
	const { config } = useConfig();
	const tracking = useTracking();
	const { onScrollToLive, isLivePage, setLiveAlreadyClosed, onRedirect } =
		useStage();

	function onClose() {
		tracking.track({
			event: 'pip_close_button',
			event_label: 'Close Live',
		});
		// localStorage.setItem('bGl2ZUlk', config.id);
		setLiveAlreadyClosed(true);
	}

	return (
		<LiveControl
			handleScrollToLive={() => onScrollToLive()}
			isLivePage={isLivePage}
			handleGoLive={(e: any) => onRedirect(e)}
			handleLiveClose={() => onClose()}
			handleMute={(e: any) => video.onMute(e)}
			isMuted={video.mute}
			isPlaying={video.play}
			onPlay={video.onPlay}
			cantMove={Boolean(config?.player_settings?.position_can_change)}
		/>
	);
}
