import React from 'react';
import { ApiProvider } from '@providers/api.provider';
import { ConfigProvider } from '@providers/config.provider';
import EmbedParamsProvider from '@providers/embedParams.provider';
import { MimoPlayerOptions } from '@utils/types';
import { Home } from '@pages/Home';
import TrackingProvider from '@providers/tracking.provider';
import { VideoProvider } from '@providers/video.provider';
import { TranslateProvider } from '@providers/translate.provider';

type AppProps = {
	options: MimoPlayerOptions;
};

export function App(props: AppProps) {
	return (
		<EmbedParamsProvider options={props.options}>
			<ApiProvider>
				<ConfigProvider>
					<TranslateProvider>
						<TrackingProvider>
							<VideoProvider>
								<Home />
							</VideoProvider>
						</TrackingProvider>
					</TranslateProvider>
				</ConfigProvider>
			</ApiProvider>
		</EmbedParamsProvider>
	);
}

export default App;
