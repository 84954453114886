import i18next from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { PropsWithChildren, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { useConfig } from './config.provider';
import React from 'react';

export function TranslateProvider({ children }: PropsWithChildren) {
	const { config } = useConfig();
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		if (config.language_slug) {
			i18next
				.use(initReactI18next)
				.use(I18NextHttpBackend)
				.init({
					backend: {
						loadPath: `${
							import.meta.env.VITE_ASSETS_URL
						}/languages/{{lng}}.json`,
					},
					load: 'currentOnly',
					lng: config.language_slug || 'pt-BR',
					fallbackLng: 'pt-BR',
				}).then(() => setIsInitialized(true));
		}
	}, [config.language_slug]);

	return <>{isInitialized && children}</>;
}
